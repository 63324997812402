import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
// import ImageList from '@material-ui/core/ImageList';
// import ImageListItem from '@material-ui/core/ImageListItem';
import { makeStyles } from '@material-ui/styles';
import "../assets/css/gallerypage.css";

import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem, {
  imageListItemClasses
} from "@mui/material/ImageListItem";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const useStyles = makeStyles({
    root: {
      width: 1000,
    //   height: 850,
    },
  });

  const theme = createTheme({
    breakpoints: {
      values: {
        mobile: 0,
        bigMobile: 350,
        tablet: 650,
        desktop: 900
      }
    }
  });

const GalleryPage = () => {

    const classes = useStyles();
    const data = useStaticQuery(query);
    //console.log(data);

    return (
        <Layout seo={data.strapiGalleryPage.seo}>        
            <div className="uk-section">
                <div className="uk-container uk-container-large">
                    <div className="gallery-container">
                        {/* <ImageList cols={4} rowHeight={264} className={classes.root} gap={10}>
                            {data.strapiGalleryPage.gallery.GalleryImage.map((item) => (
                                <ImageListItem key={item.url}>
                                    <img
                                        srcSet={`${item.url}?w=164&h=164&fit=crop&auto=format 1x,
                                            ${item.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        alt=""
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>   */}
                        <ThemeProvider theme={theme}>
                            <Box
                                m = {2}
                                p = {2}
                                sx={{
                                height: 450,
                                // backgroundColor: "pink",
                                display: "grid",
                                gridTemplateColumns: {
                                    mobile: "repeat(1, 1fr)",
                                    bigMobile: "repeat(2, 1fr)",
                                    tablet: "repeat(3, 1fr)",
                                    desktop: "repeat(4, 1fr)"
                                },
                                [`& .${imageListItemClasses.root}`]: {
                                    display: "flex",
                                    flexDirection: "column"
                                }
                                }}
                            >
                                {data.strapiGalleryPage.gallery.GalleryImage.map((item) => (
                                    <ImageListItem key={item.url} sx={{margin: 1}}>
                                        <img
                                        src={`${item.url}?w=164&fit=crop&auto=format`}
                                        srcSet={`${item.url}?w=164&fit=crop&auto=format&dpr=2 2x`}
                                        alt={item.caption}
                                        loading="lazy"
                                        />
                                        
                                    </ImageListItem>
                                ))}
                            </Box>
                            </ThemeProvider>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

const query = graphql`
    query {   
        strapiGalleryPage {
            id
            gallery {
                GalleryImage {
                    url
                    caption
                }
            }
        }
    }
`;

export default GalleryPage;